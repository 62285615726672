@import "../../../styles/index.scss";

.login-button {
    @include flexbox(row, center, center);
    background-color: $green;
    border-radius: 30px;
    width: max-content;

    .login-link {
        @include flexbox(row, none, center);
        padding: 15px;

        p {
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            margin-left: 20px;
            margin-right: 15px;
            color: $black;
        }

        img {
            width: 30px;
            height: 30px;
            margin-right: 25px;
        }
    }
}