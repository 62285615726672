@import "../../../styles/index.scss";

.iceberg-page {
    @include flexbox(column, none, center);

    .iceberg-block {
        @include flexbox(column, center, center);

        h1 {
            text-align: center;
        }

        .ice-row {
            @include flexbox(row, center, flex-start);
            width: max-content;
            height: 135px;
            margin-bottom: 10px;
            column-gap: 20px;

            .artist {
                width: 75px;
                @include flexbox(column, flex-start, center);

                .artist-image {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    /* border-radius: 50%; */
                    margin-top: 10px;
                    margin-bottom: 5px;
                }

                .artist-name {
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 10px;
                    text-align: center;
                    max-height: 20px;
                    max-width: 75px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: pre-line;
                    color: $black;
                }

                .artist-link {
                    width: 25px;
                    height: 25px;
                    margin: 12.5px;
                }
            }
        }

        .ice-row:first-of-type {
            margin-top: 25px;
        }

        .ice-row:nth-of-type(n+4) {
            .artist {
                .artist-name {
                    color: $white;
                }
            }
        }

        .iceberg-wrapper {
            margin: 20px 0px 50px 0px;
            @include flexbox(column, center, center);
        }

        .mobile {
            @include flexbox(column, center, center);
            width: 100vw;

            .iceberg-content {
                width: 280px;
                height: 1077px;
                background-repeat: no-repeat;
                @include flexbox(column, flex-start, center);
            }
        }

        .desktop {
            @include flexbox(column, center, center);
            width: 800px;

            .iceberg-content {
                width: 700px;
                height: 1089px;
                background-repeat: no-repeat;
                @include flexbox(column, flex-start, center);
            }
        }
    }
}