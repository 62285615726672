@import "../../../styles/index.scss";

.palette-page {
    @include flexbox(column, none, center);

    .palette-block {
        @include flexbox(column, center, center);

        h1 {
            text-align: center;
        }

        .palette-wrapper {
            @include flexbox(column, center, center);
        }

        @mixin ice-row($gradient-size, $max-width, $heading-margin, $description-margin) {
            @include flexbox(column, center, center);

            .gradient-zone {
                width: $gradient-size;
                height: $gradient-size;
                border-radius: 15px;
            }

            .heading {
                margin-top: $heading-margin;
                width: $max-width;
                text-align: center;

                span {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;

                    span {
                        font-weight: 700;

                    }
                }
            }

            .description {
                width: $max-width;
                margin-top: $description-margin;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                opacity: 0.8;
                text-align: center;
            }
        }

        .mobile {
            width: max-content;
            @include flexbox(column, center, center);
            @include ice-row(280px, 280px, 25px, 10px)
        }

        .desktop {
            width: max-content;
            @include flexbox(column, center, center);
            @include ice-row(400px, 500px, 40px, 25px)
        }
    }
}