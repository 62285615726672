@import "../../../styles/index.scss";

.audio_features-page {
    .download-block {
        margin-top: 25px;
    }

    @include flexbox(column, none, center);

    .audio_features-block {
        @include flexbox(column, center, center);
        margin-bottom: 50px;

        h1 {
            text-align: center;
            width: 280px;

            @media (min-width: 1000px) {
                width: 800px;
            }
        }

        @mixin wrapper() {
            display: flex;
            flex-wrap: wrap;
        }

        @mixin audio_features-list($r) {
            .audio_features-list {
                display: grid;
                justify-content: center;
                grid-template-columns: repeat($r, 1fr);
                column-gap: 40px;
                row-gap: 20px;
                padding-top: 20px;

                .audio_features-card {
                    @include flexbox(column, center, center);
                    padding: 30px;

                    .audio_features-level_feature {
                        @include flexbox(column, center, center);

                        .p {
                            text-align: center
                        }

                        .audio_features-level_feature-body {
                            @include flexbox(column, center, center);
                            border-radius: 30px;
                            overflow: hidden;

                            .image_zone {
                                @include flexbox(column, center, center);
                                position: absolute;
                                z-index: 300;

                                img {
                                    width: 64px;
                                    height: 64px;
                                }

                                .feature-value {
                                    padding-top: 5px;
                                    text-align: center;
                                    font-size: 32px;
                                    line-height: 39px;
                                }
                            }



                            .audio_features-level_feature-body-full {
                                @include flexbox(column, flex-end, center);

                                background-color: $dimed;
                                width: 150px;
                                height: 200px;

                                .audio_features-level_feature-body-filled {
                                    background-color: $green;
                                    width: 150px;
                                }
                            }
                        }

                    }

                    .audio_features-number_feature {
                        @include flexbox(column, center, center);
                        width: 147px;
                        height: 147px;
                        background-color: $dimed;
                        border-radius: 100%;
                        border: 2px solid $green;

                        img {
                            width: 64px;
                            height: 64px;
                        }

                        .feature-value {
                            padding-top: 5px;
                            text-align: center;
                            font-size: 32px;
                            line-height: 39px;
                        }
                    }

                    .feature-name {
                        padding-top: 20px;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 29px;
                    }

                    .feature-interval {
                        padding-top: 5px;
                        font-size: 20px;
                        line-height: 24px;
                        color: $grey
                    }
                }
            }

        }

        .mobile {
            @include wrapper();
            @include audio_features-list(1);
        }

        .desktop {
            @include wrapper();
            @include audio_features-list(6);

            .audio_features-card {
                grid-column: span 2;

                &:last-child:nth-child(3n - 1) {
                    grid-column-end: -2;
                }

                &:nth-last-child(2):nth-child(3n + 1) {
                    grid-column-end: 4;
                }

                &:last-child:nth-child(3n - 2) {
                    grid-column-end: 5;
                }
            }
        }
    }
}

.download-block{
    z-index: 300;
}