@import "../../../styles/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Sedgwick+Ave+Display&display=swap");

.poster-page {
  @include flexbox(column, none, center);

  .poster-block {
    @include flexbox(column, center, center);

    h1 {
      text-align: center;
    }

    .poster-wrapper {
      @include flexbox(column, center, center);
    }

    @mixin poster-content(
      $width,
      $heading-width,
      $festname,
      $cityname,
      $margins,
      $headliner,
      $artists,
      $description
    ) {
      .poster-content {
        @include flexbox(column, center, center);
        width: $width;

        .heading {
          @include flexbox(column, center, center);
          width: $heading-width;

          p {
            font-family: "Sedgwick Ave Display", "cursive";
          }

          .festname {
            font-size: $festname;
            line-height: 120%;
          }

          .cityname {
            width: $heading-width;
            text-align: end;
            font-size: $cityname;
            line-height: 120%;
          }
        }

        .rows {
          @include flexbox(column, center, center);

          p {
            white-space: nowrap;
          }

          .row {
            @include flexbox(column, center, center);
            margin-top: calc(10px + $margins);

            .headliner {
              font-weight: 900;
              font-size: $headliner;
              line-height: 120%;
              margin-bottom: $margins;
            }

            .artists {
              font-weight: 700;
              font-size: $artists;
              line-height: 120%;
              text-align: center;
            }

            .description {
              font-weight: 900;
              font-size: $description;
              line-height: 120%;
            }
          }
        }
      }
    }

    .mobile {
      width: max-content;
      @include flexbox(column, center, center);
      @include poster-content(280px, 200px, 40px, 12px, 5px, 24px, 12px, 10px);
    }

    .desktop {
      width: max-content;
      @include flexbox(column, center, center);
      @include poster-content(500px, 300px, 48px, 16px, 10px, 32px, 16px, 12px);
    }
  }
}
