@import "../../../styles/index.scss";

.tracks-page {
    @include flexbox(column, none, center);

    .tracks-block {
        @include flexbox(column, flex-start, center);
        margin-bottom: 55px;

        .desktop {
            @include flexbox(column, center, center);
            width: 800px;

            h1 {
                text-align: center;
            }

            table {
                margin-top: 10px;
            }

            tr {
                @include flexbox(row, none, center);

                .number {
                    @include flexbox(column, center, center);
                    // padding: 15px;
                    width: 45px;
                    height: 45px;

                    p {
                        font-size: 16px
                    }
                }

                .image {
                    @include flexbox(column, center, center);
                    width: 55px;
                    height: 55px;

                    img {
                        width: 45px;
                        height: 45px;
                    }
                }

                .credentials {
                    padding-left: 5px;
                    width: 350px;

                    .track-name {
                        font-size: 16px;
                    }

                    p {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    .artists {
                        font-size: 12px;
                        color: $grey;
                    }
                }

                .album {
                    width: 200px;
                    padding-left: 50px;

                    p {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }

                .time {
                    padding-left: 20px;
                    padding-right: 20px;
                    font-size: 12px;
                }
            }
        }

        .mobile {
            .mobile-tracks {
                @include flexbox(column, center, center);
                width: 280px;
                place-self: center;

                table {
                    margin-top: 10px;
                }

                tr {
                    @include flexbox(row, none, center);

                    .image {
                        @include flexbox(column, center, center);
                        width: 55px;
                        height: 55px;

                        img {
                            width: 45px;
                            height: 45px;
                        }
                    }

                    .credentials {
                        padding-left: 5px;
                        width: 225px;
                        text-align: start;

                        p {
                            @include text-overflow();
                            text-align: start;
                        }

                        .artists-list {
                            font-size: smaller;
                            color: $grey;
                        }
                    }
                }
            }
        }
    }
}