@import "../../../styles/index.scss";

.your_spotify-page {
    @include flexbox(column, none, center);

    .your_spotify-block {
        @include flexbox(column, flex-start, center);

        h1 {
            text-align: center;
        }

        .your_melodystats-title {
            color: $green;
            width: 100%;
            padding: 30px 0px 50px 0px;
            font-size: 50px;

            @media (min-width: 1000px) {
                padding: 60px 0px 150px 0px;
                font-size: 100px;
            }

            $animation-duration: 5s; // Set the duration of the animation

            @keyframes textGradientAnimation {
                0% {
                    background-position: 0% 0%;
                }

                50% {
                    background-position: 50% 0%;
                }

                100% {
                    background-position: 100% 0%;
                }
            }

            $text-gradient: linear-gradient(90deg, $green, $green, $green, $yellow, $red, $violet, $blue, $violet, $red, $yellow, $green, $green, $green, $green);
            background: $text-gradient;
            background-size: 500% 500%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: textGradientAnimation $animation-duration forwards;
        }

        @mixin general-grid($gap) {
            .grid {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                row-gap: $gap;
                justify-content: center;
                align-items: center;
            }
        }

        @mixin profile($photo, $blur, $spread) {
            .profile {
                @include flexbox(column, center, center);
                margin-bottom: 75px;

                img {
                    width: $photo;
                    height: $photo;
                    margin: 30px;
                    object-fit: cover;
                    /* border-radius: 50%; */
                }

                .profile_empty-photo {
                    margin: 30px;
                }

                p {
                    font-size: 20px;
                    line-height: 24px;
                }
            }
        }

        @mixin title($margin) {
            .title {
                font-size: 32px;
                line-height: 39px;
                z-index: 330;
                margin-bottom: $margin;
            }
        }

        @mixin track($color, $width, $text-width) {
            .block {
                @include flexbox(row, flex-start, center);
                padding: 15px;
                background: $color;
                border-radius: 20px;
                width: $width;

                .track-image {
                    width: 100px;
                    height: 100px;
                }

                .spotify_logo_link {
                    margin-top: 15px;

                    img {
                        height: 25px;
                        width: 25px;
                    }
                }

                .track-data {
                    @include flexbox(column, space-between, flex-start);
                    height: 100px;
                    margin-left: 12.5px;

                    .text {
                        @include flexbox(column, space-between, flex-start);

                        .name {
                            width: $text-width;
                            @include text-overflow();
                            font-size: 16px;

                            @media (min-width: 1000px) {
                                font-size: 20px;
                            }

                            margin-bottom: 5px;
                        }

                        .artists-list {
                            width: $text-width;
                            text-align: start;
                            @include text-overflow();
                            font-size: 12px;

                            @media (min-width: 1000px) {
                                font-size: 16px;
                            }

                            color: $black;
                        }
                    }
                }
            }
        }

        @mixin artists($width, $photo, $blur, $spread, $vertical, $horizontal, $font-size, $line-height) {
            .artists {
                @include flexbox(column, center, center);
                @include title(30px);

                .info {
                    @include flexbox(row, center, flex-start);
                    position: relative;
                    width: $width;

                    #artists1 {
                        z-index: 320;
                        position: absolute;
                        top: 0;
                    }

                    #artists2 {
                        top: 0;
                        z-index: 310;
                        @include flexbox(row, space-between, center);
                        width: calc(100% + $horizontal);
                        margin-top: $vertical;
                    }

                    .card {
                        @include flexbox(column, none, center);

                        img {
                            width: $photo;
                            height: $photo;
                            object-fit: cover;
                            /* border-radius: 50%; */
                        }

                        p {
                            margin-top: 25px;
                            font-weight: 600;
                            font-size: $font-size;
                            line-height: $line-height;
                            @include text-overflow();
                            width: $photo;
                            text-align: center;
                        }
                    }
                }
            }
        }

        @mixin current-player($width, $text-width) {
            .current-player {
                @include flexbox(column, center, center);
                @include title(30px);

                .info {
                    @include flexbox(column, center, center);
                    padding: 40px 0px;

                    @include track($green, $width, $text-width);
                }
            }
        }

        @mixin tracks() {
            .tracks {
                @include flexbox(column, center, center);
                @include title(30px);
                width: 800px;

                table {
                    margin-top: 30px;
                }

                tr {
                    @include flexbox(row, none, center);

                    .number {
                        @include flexbox(column, center, center);
                        // padding: 15px;
                        width: 45px;
                        height: 45px;

                        p {
                            font-size: 16px
                        }
                    }

                    .image {
                        @include flexbox(column, center, center);
                        width: 55px;
                        height: 55px;

                        img {
                            width: 45px;
                            height: 45px;
                        }
                    }

                    .credentials {
                        padding-left: 5px;
                        width: 350px;
                        text-align: start;

                        p {
                            @include text-overflow();
                            text-align: start;
                        }

                        .artists-list {
                            font-size: smaller;
                            color: $grey;
                        }
                    }

                    .album-name {
                        width: 200px;
                        padding-left: 50px;

                        p {
                            @include text-overflow();
                            font-size: smaller;
                        }
                    }

                    .time {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
            }
        }

        @mixin mobile-tracks() {
            .mobile-tracks {
                @include flexbox(column, center, center);
                @include title(30px);
                width: 280px;
                place-self: center;

                table {
                    margin-top: 30px;
                }

                tr {
                    @include flexbox(row, none, center);

                    .image {
                        @include flexbox(column, center, center);
                        width: 55px;
                        height: 55px;

                        img {
                            width: 45px;
                            height: 45px;
                        }
                    }

                    .credentials {
                        padding-left: 5px;
                        width: 225px;
                        text-align: start;

                        p {
                            @include text-overflow();
                            text-align: start;
                        }

                        .artists-list {
                            font-size: smaller;
                            color: $grey;
                        }
                    }
                }
            }
        }

        @mixin album($size, $text-width) {
            .album {
                @include flexbox(column, center, center);
                @include title(30px);

                .info {
                    @include flexbox(column, center, center);
                    padding: 40px 0px;

                    .album-image {
                        width: $size;
                        height: $size;
                    }

                    .spotify_logo_link {
                        margin-top: 15px;

                        img {
                            height: 25px;
                            width: 25px;
                        }
                    }

                    .text {
                        @include flexbox(column, center, center);
                        margin-top: 25px;
                        width: $text-width;
                        text-align: center;

                        .name {
                            font-size: 24px;
                            line-height: 29px;
                        }

                        .artist {
                            font-size: 20px;
                            line-height: 24px;
                            color: $grey;
                        }
                    }
                }
            }
        }

        @mixin genre($font-size, $line-height) {
            .genre {
                @include flexbox(column, center, center);
                @include title(30px);

                .info {
                    @include flexbox(column, center, center);
                    padding: 40px 0px;

                    p {
                        font-weight: 700;
                        font-size: $font-size;
                        line-height: $line-height;
                        color: $green;
                    }
                }
            }
        }

        @mixin mood-song($width, $text-width) {
            .mood-song {
                @include flexbox(column, center, center);
                @include title(30px);

                .info {
                    @include flexbox(column, center, center);
                    padding: 40px 0px;

                    .happy {
                        @include flexbox(column, center, center);
                        padding: 20px 0px;
                        @include track($red, $width, $text-width);
                    }

                    .sad {
                        @include flexbox(column, center, center);
                        padding: 20px 0px;
                        @include track($grey, $width, $text-width);
                    }
                }
            }
        }

        @mixin decade($font-size, $line-height) {
            .decade {
                @include flexbox(column, center, center);
                @include title(30px);

                .info {
                    @include flexbox(column, center, center);
                    padding: 40px 0px;

                    p {
                        font-weight: 700;
                        font-size: $font-size;
                        line-height: $line-height;
                        color: $green;
                    }
                }
            }
        }

        @mixin see-more($margin) {
            .see_more {
                margin-top: $margin;
            }
        }

        .desktop {
            @include profile(300px, 200px, 7px);
            @include general-grid(175px);
            @include see-more(40px);
            @include artists(550px, 200px, 40px, 7px, 75px, 75px, 24px, 29px);
            @include current-player(415px, 300px);
            @include tracks();
            @include album(300px, 500px);
            @include genre(55px, 53px);
            @include mood-song(415px, 300px);
            @include decade(200px, 191px);
            margin-bottom: 100px;
        }

        .mobile {
            @include profile(100px, 40px, 7px);
            @include general-grid(100px);
            @include see-more(20px);
            @include artists(265px, 100px, 20px, 3px, 30px, 30px, 16px, 19px);
            @include current-player(280px, 175px);
            @include mobile-tracks();
            @include album(175px, 280px);
            @include genre(32px, 31px);
            @include mood-song(280px, 175px);
            @include decade(75px, 72px);
            margin-bottom: 50px;
        }
    }
}