@import "../../../styles/index.scss";

.patrons-page {
  @include flexbox(column, none, center);

  .patrons-block {
    @include flexbox(column, center, center);

    h1 {
      text-align: center;
    }

    .patrons-wrapper {
      @include flexbox(column, center, center);
      margin-top: 40px;

      .patrons-subscription {
        @include flexbox(column, center, center);
        margin-bottom: 30px;

        .patrons-subscription-name {
          font-family: "Sedgwick Ave Display", "cursive";
          font-size: 48px;
          margin-bottom: 10px;
        }

        .patrons-subscription-patrons_list {
          @include flexbox(column, center, center);

          p {
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            margin-bottom: 10px;
            width: 280px;
            text-align: center;

            @media (min-width: 1000px) {
              width: 500px;
            }
            @include text-overflow();
          }
        }

        .patrons-subscription-get_status {
          @include flexbox(column, center, center);

          .patrons-subscription-get_status-header {
            font-size: 16px;
            margin-bottom: 15px;
            width: 280px;
            text-align: center;

            @media (min-width: 1000px) {
              width: 500px;
            }
          }

          .patrons-subscription-get_status-button {
            margin-bottom: 5px;
            div {
              @include flexbox(column, center, center);
              border-radius: 30px;
            }

            p {
              font-size: 13px;
              font-weight: 600;
              line-height: normal;
              margin: 10px 15px;
            }
          }
        }

        .patrons-subscription-join {
          margin: 5px 0px;

          div {
            @include flexbox(column, center, center);
            border-radius: 30px;
          }

          p {
            font-size: 13px;
            font-weight: 600;
            line-height: normal;
            margin: 10px 15px;
          }
        }
      }
    }
  }
}
