@import "../../../styles/index.scss";

.mobile_menu {
  @include flexbox(column, flex-start, center);
  width: 375px;

  .highlighted_nav_link {
    color: $green;
  }

  .common {
    @include flexbox(row, flex-start, center);
    width: 280px;
    height: 51px;
    border: 1px solid $green;
    border-radius: 20px;
    margin-top: 12.5px;
    margin-bottom: 12.5px;

    .emoji {
      @include flexbox(row, center, center);
      margin-left: 20px;
      margin-right: 20px;

      img {
        width: 32px;
        height: 32px;
      }
    }

    .name {
      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  .dropdown {
    @include flexbox(column, flex-start, flex-start);
    width: 280px;
    border: 1px solid $green;
    border-radius: 20px;
    margin-top: 12.5px;
    margin-bottom: 12.5px;

    .content {
      @include flexbox(column, center, flex-start);
      margin-left: 20px;
      padding-top: 9.5px;
      padding-bottom: 9.5px;

      .dropdown-name {
        @include flexbox(row, flex-start, center);

        .emoji {
          @include flexbox(row, center, center);
          margin-right: 20px;

          img {
            width: 32px;
            height: 32px;
          }
        }

        .moreOption {
          @include flexbox(row, none, center);

          p {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
          }

          .moreIcon {
            width: 20px;
            height: 20px;
            @include flexbox(row, center, center);
          }
        }
      }

      .dropdown-options {
        @include flexbox(column, flex-start, flex-start);
        margin-top: 10px;
        margin-left: 20px;

        li {
          div {
            @include flexbox(row, flex-start, center);
            height: 44px;

            a {
              p {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}
