@import "../../../styles/index.scss";

.privacy-page {
    @include flexbox(column, none, center);


    .privacy-block {
        @include flexbox(column, center, center);

        line-height: 120%;

        .default-link {
            @include flexbox(row, center, center);
            border-radius: 30px;
            border: 1px solid $dimed;
            margin: 20px 0px;

            p {
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                margin: 10px;

            }
        }

        p {
            font-weight: 300;
            margin: 5px 0px 10px 0px;
        }

        h1 {
            text-align: center;
            margin-bottom: 40px;
        }

        @mixin privacy-content($width, $margin-bottom) {
            .privacy-content {
                width: $width;
                line-height: 120%;
                margin-bottom: $margin-bottom;

                .heading {
                    font-size: 24px;
                    font-weight: 500;
                    margin-top: 35px;
                    margin-bottom: 15px;
                    line-height: 120%;
                }

                ul {
                    margin-left: 20px;
                    margin-bottom: 10px;

                    li {
                        margin-bottom: 5px;
                    }
                }

                a {
                    color: $green;
                    font-weight: 500;
                }
            }
        }

        .mobile {
            @include privacy-content(280px, 25px);
            margin-bottom: 50px;
        }

        .desktop {
            @include privacy-content(775px, 50px);
        }
    }
}