@import "../../../styles/index.scss";

.popup {
    @include flexbox(column, center, center);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;

    @media (min-width: 1000px) {
        width: 500px;
    }

    height: max-content;
    background-color: $green;
    backdrop-filter: blur(1px);

    border-radius: 20px;
    z-index: 410;

    text-align: center;

    .popup-wrapper {
        @include flexbox(column, center, center);
        padding: 15px;

        .close_button-zone {
            @include flexbox(row, flex-end, center);
            width: 270px;
            margin-bottom: 15px;

            @media (min-width: 1000px) {
                width: 470px;
            }

            img {
                cursor: pointer;
            }
        }

        .content-zone {
            @include flexbox(column, center, center);

            .primary-heading {
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: $white;
                margin-bottom: 10px;
            }

            .primary {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: $white;
            }

            .description-block {
                @include flexbox(column, center, center);
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                color: $black;

                p {
                    margin: 10px 0px 5px 0px;
                }

                a {
                    font-weight: 600;
                }
            }

            .okay_button {
                @include flexbox(column, center, center);
                background-color: $black;
                border-radius: 30px;
                margin-top: 20px;

                p {
                    color: $white;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 10px 15px;
                }
            }

            .promotion_link {
                @include flexbox(column, center, center);
                margin-top: 20px;

                a {
                    @include flexbox(row, center, center);

                    p {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-decoration-line: underline;
                        margin-right: 10px;
                    }

                    img {
                        width: 36px;
                        height: 36px;
                    }
                }
            }

            .promotion_button {
                @include flexbox(column, center, center);
                background-color: $black;
                border-radius: 30px;
                margin-top: 20px;

                a {
                    @include flexbox(row, center, center);
                    margin: 10px 15px;

                    p {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin-right: 10px;
                    }

                    img {
                        width: 36px;
                        height: 36px;
                    }
                }
            }
        }
    }
}