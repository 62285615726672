@import "../../../styles/index.scss";

.artists-page {
    @include flexbox(column, none, center);

    .artists-block {
        @include flexbox(column, center, center);

        h1 {
            text-align: center;
        }

        margin-bottom: 50px;

        @media (min-width: 1000px) {
            margin-bottom: 150px;
        }
    }
}