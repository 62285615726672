@import "../../../styles/index.scss";


.home-page {
    @include flexbox(column, none, center);

    .home-block {
        @include flexbox(column, center, center);

        .mobile {
            @include flexbox(column, center, center);
            margin-bottom: 50px;

            .photo {
                @include flexbox(column, center, center);
                height: 200px;
                margin-bottom: 10px;

                img {
                    width: 150px;
                    height: 150px;
                    object-fit: cover;
                    /* border-radius: 50%; */
                }
            }

            .verdict-content {
                margin-bottom: 40px;

                p {
                    width: 280px;
                    text-align: center;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 300;

                    span {
                        font-weight: 600;
                        color: $green;
                    }
                }
            }

            .verdict-link {
                @include flexbox(column, center, center);
                border-radius: 30px;
                background-color: $green;
                width: 280px;
                height: 50px;

                p {
                    color: $black;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 20px;
                }
            }

            .default-link {
                @include flexbox(row, center, center);
                border-radius: 30px;
                border: 1px solid $dimed;
                margin-top: 100px;

                img {
                    width: 40px;
                    height: 40px;
                    margin: 5px 15px 5px 5px;
                }

                p {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 16px;
                    margin-right: 20px;
                }
            }
        }

        .desktop {
            @include flexbox(column, center, center);
            margin-bottom: 50px;

            .photo {
                @include flexbox(column, center, center);
                height: 360px;
                margin-bottom: 10px;

                img {
                    width: 300px;
                    height: 300px;
                    object-fit: cover;
                    /* border-radius: 50%; */
                }
            }

            .verdict-content {
                margin-bottom: 40px;

                p {
                    width: 500px;
                    text-align: center;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 300;

                    span {
                        font-weight: 600;
                        color: $green;
                    }
                }
            }

            .verdict-link {
                @include flexbox(column, center, center);
                border-radius: 30px;
                background-color: $green;
                width: 280px;
                height: 50px;

                p {
                    color: $black;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 20px;
                }
            }

            .default-link {
                @include flexbox(row, center, center);
                border-radius: 30px;
                border: 1px solid $dimed;
                margin-top: 60px;

                img {
                    width: 40px;
                    height: 40px;
                    margin: 5px 15px 5px 5px;
                }

                p {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 16px;
                    margin-right: 20px;
                }
            }
        }

    }

    .home-login {
        .mobile {
            @include flexbox(column, center, center);
            height: 80vh;

            .text-part {
                @include flexbox(column, center, center);

                width: 280px;
                text-align: center;

                h1 {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 32px;
                    margin-bottom: 40px;
                }

                .cta {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                    margin-bottom: 40px
                }
            }

        }

        .desktop {
            @include flexbox(column, center, center);
            height: 80vh;

            .text-part {
                @include flexbox(column, center, center);
                text-align: center;
                margin-bottom: 85px;

                h1 {
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 40px;
                    margin-bottom: 50px;
                    width: 500px;
                }

                .cta {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                    margin-bottom: 40px;
                    width: 700px;
                }
            }

            .features-list {
                @include flexbox(row, center, center);

                .feature-card {
                    @include flexbox(column, center, center);
                    background-color: $dimed;
                    border-radius: 20px;
                    margin: 0px 15px;

                    p {
                        text-align: center;
                        width: 220px;

                    }

                    .card-content {
                        @include flexbox(column, center, center);
                        margin: 15px;

                        .card-heading {
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 20px;
                            margin-bottom: 15px;
                        }

                        img {
                            margin-bottom: 15px;
                        }

                        .small_emoji {
                            width: 42px;
                            height: 42px;
                        }

                        .big_emoji {
                            width: 64px;
                            height: 64px;
                        }

                        .card-description {
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }
                }
            }
        }
    }
}