@import "../../../styles/index.scss";

.genres-page {
    @include flexbox(column, none, center);

    .genres-block {
        @include flexbox(column, center, center);

        h1 {
            text-align: center;
        }

        .wrapper {
            @include flexbox(column, flex-start, center);

            .genre-verdict {
                @include flexbox(column, center, center);
                margin-bottom: 30px;

                p {
                    font-size: 16px;
                    text-align: center;

                    span {
                        color: $green;
                        font-weight: 600;
                    }
                }
            }

            .genre-block {
                @include flexbox(column, flex-start, flex-start);
                width: 280px;

                @media (min-width: 1000px) {
                    width: 500px;
                }

                margin-bottom: 30px;

                .genre-name {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 10px;
                    max-width: 280px;

                    @media (min-width: 1000px) {
                        max-width: 500px
                    }
                }

                .genre-bar-wrapper {
                    @include flexbox(column, center, center);
                    position: relative;

                    .genre-bar {
                        @include flexbox(row, flex-start, flex-start);
                        height: 40px;
                        width: 280px;
                        overflow: hidden;

                        @media (min-width: 1000px) {
                            width: 500px;
                        }

                        border-radius: 15px;
                        background-color: $dimed;

                        .genre-bar-filled {
                            height: 40px;
                            background-color: $green;
                        }
                    }

                    .percentage {
                        position: absolute;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }
        }
    }
}