@import "../../../styles/index.scss";

.recommendations-page {
    @include flexbox(column, none, center);

    .recommendations-block {
        @include flexbox(column, center, center);

        h1 {
            text-align: center;
        }

        @mixin recommendations-mix($margin-top,
            $heading-max-width,
            $block-width,
            $margin-bottom-block,
            $recommendation-heading-margin-bottom,
            $heading-cut,
            $artist-image,
            $artist-image-height,
            $track-sample-margin,
            $credentials-width,
            $album-name-margin-bottom,
            $album-cover-margin-bottom,
            $track-big-size,
            $track-big-margin-bottom,
            $track-big-text-height,
            $track-big-text-width,
            $artist-track-name-font-size,
            $artist-track-name-line-height,
            $artist-track-artists-font-size,
            $artist-track-artists-line-height) {
            @include flexbox(column, center, center);
            margin-top: $margin-top;

            .heading {
                font-size: 24px;
                line-height: 29px;
                max-width: $heading-max-width;
                text-align: center;

                span {
                    color: $green;
                    font-weight: 600;
                }
            }

            .recommendation-block {
                @include flexbox(column, center, center);
                width: $block-width;
                margin-bottom: $margin-bottom-block;

                .recommendation-content {
                    @include flexbox(column, center, center);
                    width: $block-width;
                    border-radius: 20px;
                    background-color: $green;
                    padding-bottom: 20px;

                    .recommendation-heading {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 29px;
                        margin: 15px 0px $recommendation-heading-margin-bottom 0px;
                        width: calc($block-width - $heading-cut);
                        text-align: center;
                    }
                }
            }

            .track_based_on_artist {
                .spotify_link {
                    margin-bottom: 30px;
                }

                .artist_image {
                    @include flexbox(column, center, center);
                    height: $artist-image-height;

                    img {
                        width: $artist-image;
                        height: $artist-image;
                        object-fit: cover;
                        /* border-radius: 50%; */
                    }
                }

                .track-big {
                    @include flexbox(row, space-between, center);
                    width: calc($block-width - 30px);
                    color: $black;
                    margin-bottom: 2cqb;

                    .track-main_content {
                        @include flexbox(row, center, center);

                        @media screen and (min-width: 1000px) {
                            margin-bottom: 0px;
                        }

                        img {
                            width: $track-big-size;
                            height: $track-big-size;
                            margin-right: 10px;
                        }

                        .track-main_content-text {
                            @include flexbox(column, space-between, flex-start);
                            height: $track-big-text-height;

                            p {
                                width: $track-big-text-width;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }

                            .track-name {
                                font-size: $artist-track-name-font-size;
                                line-height: $artist-track-name-line-height;
                            }

                            .track-artists {
                                font-size: $artist-track-artists-font-size;
                                line-height: $artist-track-artists-line-height;
                            }
                        }
                    }

                    .link {
                        @include flexbox(column, center, flex-end);
                        height: 55px;
                        width: 40px;

                        @media (min-width: 1000px) {
                            width: 75px;
                        }

                        img {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }

            .track-sample {
                margin: $track-sample-margin 0px;
                width: calc($block-width - 30px);

                tr {
                    @include flexbox(row, space-between, center);

                    .image {
                        @include flexbox(column, center, center);
                        width: 55px;
                        height: 55px;

                        img {
                            width: 45px;
                            height: 45px;
                        }
                    }

                    .credentials {
                        margin-left: 5px;
                        width: $credentials-width;

                        p {
                            @include text-overflow();
                            text-align: start;
                        }

                        .artists-list {
                            font-size: smaller;
                            color: $grey;
                        }
                    }

                    .album {
                        width: 200px;

                        p {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }

                    .time {
                        @include flexbox(column, center, center);
                        font-size: 12px;
                        text-align: center
                    }
                }
            }

            .album_based_on_track {
                @include flexbox(row, space-between, center);

                .spotify_link {
                    margin-bottom: 30px;
                }

                .album-recommendation {
                    @include flexbox(column, center, center);
                    margin-bottom: 15px;

                    @media screen and (min-width: 1000px) {
                        margin-bottom: 0px;
                    }

                    .name {
                        color: $black;
                        font-size: 20px;
                        line-height: 24px;
                        width: calc($block-width - 30px);
                        text-align: center;
                        margin-bottom: $album-name-margin-bottom;
                    }

                    .cover {
                        width: $artist-image;
                        height: $artist-image;
                        margin-bottom: $album-cover-margin-bottom;
                    }
                }
            }

            .tracks_based_on_track {
                .tracks-mobile {
                    width: 250px;

                    .cell {
                        @include flexbox(column, center, center);
                        margin-bottom: 30px;

                        .spotify_link {
                            margin: 5px 0px;
                        }

                        tr {
                            @include flexbox(row, none, center);

                            .image {
                                @include flexbox(column, center, center);
                                width: 55px;
                                height: 55px;

                                img {
                                    width: 45px;
                                    height: 45px;
                                }
                            }

                            .credentials {
                                margin-left: 5px;
                                width: 180px;
                                color: $black;

                                p {
                                    @include text-overflow();
                                    text-align: start;
                                }

                                .artists-list {
                                    font-size: smaller;
                                }
                            }
                        }
                    }
                }

                .tracks-desktop {
                    margin-bottom: 30px;
                    width: 545px;

                    tr {
                        @include flexbox(row, none, center);
                        margin-bottom: 20px;

                        .image {
                            @include flexbox(column, center, center);
                            width: 55px;
                            height: 55px;z

                            img {
                                width: 45px;
                                height: 45px;
                            }
                        }

                        .credentials {
                            margin-left: 5px;
                            width: 230px;
                            color: $black;
                            margin-right: 30px;

                            .track-name {
                                font-size: 16px;
                            }

                            p {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }

                            .artists {
                                font-size: 12px;
                            }
                        }

                        .time {
                            @include flexbox(column, center, center);
                            font-size: 12px;
                            text-align: center;
                            color: $black;
                            flex-grow: 1;
                        }
                    }
                }
            }
        }

        .mobile {
            @include flexbox(column, center, center);
            @include recommendations-mix(30px, 280px, 280px, 100px, 25px, 30px, 100px, 130px, 25px, 225px, 25px, 10px, 45px, 25px, 36px, 200px, 16px, 19px, 12px, 15px)
        }

        .desktop {
            @include flexbox(column, center, center);
            @include recommendations-mix(55px, 700px, 575px, 120px, 40px, 60px, 200px, 260px, 30px, 250px, 40px, 25px, 100px, 40px, 80px, 400px, 24px, 29px, 20px, 24px)
        }
    }

    .running_line {
        text-transform: uppercase;
        overflow-x: hidden;
        border: 1px solid;
        border-color: $white transparent;

        .running_content {
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;

            .text {
                display: flex;
                flex-shrink: 0;
                align-items: center;
                animation: slide-left 20s linear infinite;

                p {
                    font-size: 10px;
                    line-height: 10px;
                    margin: 10px 6px;
                }

                @keyframes slide-left {
                    from {
                        -webkit-transform: translateX(0);
                        transform: translateX(0);
                    }

                    to {
                        -webkit-transform: translateX(-100%);
                        transform: translateX(-100%);
                    }
                }
            }
        }
    }

    .spotify_link {
        @include flexbox(row, flex-start, center);
        height: 50px;
        border-radius: 30px;
        background-color: $black;
        min-width: max-content;

        img {
            width: 25px;
            height: 25px;
            margin: 0px 12.5px 0px 30px;
        }

        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            margin-right: 30px;
            @include flexbox(row, none, none);

            .limited {
                max-width: 80px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}