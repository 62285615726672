@import "../../../styles/index.scss";

.not_found {
    @include flexbox(column, center, center);
    text-align: center;
    height: 70vh;

    p {
        width: 280px;

        @media (min-width: 1000px) {
            width: 500px;
        }
    }

    .header {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 25px;
    }

    a {
        color: $green;
        text-decoration: underline;
    }
}