@import "../../../styles/index.scss";

nav {
    @include flexbox(column, center, center);
    min-height: 80px;

    .highlighted_nav_link {
        color: $green;
    }

    .branding {
        margin-left: 20px;

        a {
            @include flexbox(row, flex-start, center);

            .logo {
                margin-right: 15px;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .naming {
                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 23px;
                    color: $green;
                }
            }
        }
    }

    .mobile {
        width: 375px;
        @include flexbox(row, space-between, center);

        .burgerMenu {
            @include flexbox(row, center, center);
            width: 35px;
            height: 35px;
            margin-right: 20px;

            .hamburger-react {
                cursor: none !important;
            }
        }
    }

    .desktop {
        width: 1000px;
        @include flexbox(row, space-between, center);

        .general_navigation {
            @include flexbox(row, flex-start, center);

            .moreOption {
                @include flexbox(row, none, center);

                .option_name {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    cursor: pointer;
                }

                .moreIcon {
                    width: 20px;
                    height: 20px;
                    @include flexbox(row, center, center);
                    cursor: pointer;
                }
            }

            a {
                margin-left: 15px;
                margin-right: 15px;

                p {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                }
            }

            .dropdown_menu {
                background: #191414;
                backdrop-filter: blur(1px);
                border-radius: 10px;
                margin-top: 15px;

                .dropdown_menu-content {
                    @include flexbox(column, space-evenly, none);
                    padding: 5px;
                    margin-left: 5px;

                    a {
                        line-height: normal;
                    }

                    li {
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .user_navigation {
            margin-right: 20px;
            @include flexbox(row, flex-end, center);

            .login {
                @include flexbox(row, center, center);
                padding: 8px;
                border-radius: 10px;
                background-color: $green;
                font-weight: 600;
                line-height: 1;
            }

            .profile {
                @include flexbox(row, center, center);

                img {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    /* border-radius: 50%; */
                }
            }
        }

    }
}