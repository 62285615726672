@import "../../../styles/index.scss";

footer {
    @include flexbox(row, center, center);
    height: 100px;
    width: 100vw;

    .footer-content {
        @include flexbox(column, center, center);

        .footer-terms_link {
            color: $grey;
            font-size: 12px;
        }

        :not(:first-child) {
            margin-top: 10px;
        }
    }
}