@import "../../../styles/index.scss";

.tracks-table {
    margin: 10px 0px;
    width: 280px;

    @media (min-width: 1000px) {
        width: 800px;
    }

    tr {
        @include flexbox(row, center, center);
        margin: 10px 0px;

        .number {
            display: none;

            @media (min-width: 1000px) {
                @include flexbox(column, center, center);
            }

            width: 45px;
            height: 45px;

            p {
                font-size: 16px
            }
        }

        .image {
            @include flexbox(column, center, center);
            width: 55px;
            height: 55px;

            img {
                width: 45px;
                height: 45px;
            }
        }

        .credentials {
            padding-left: 5px;
            text-align: start;
            width: 225px;

            .credentials-track_name {
                font-size: 16px;
            }

            .credentials-artists {
                font-size: 12px;
                color: $grey;
            }

            @media (min-width: 1000px) {
                width: 350px;
            }

            p {
                @include text-overflow();
                text-align: start;
            }
        }

        .tracks-table-album_name {
            width: 200px;
            padding-left: 50px;
            display: none;

            @media (min-width: 1000px) {
                display: block;
            }

            p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-size: 12px;
                font-weight: 600;
            }
        }

        .link {
            @include flexbox(column, center, flex-end);
            height: 55px;
            width: 40px;

            @media (min-width: 1000px) {
                width: 75px;
            }

            img {
                width: 25px;
                height: 25px;
            }
        }
    }
}