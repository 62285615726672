@import "../../../styles/index.scss";

.block {
    margin-bottom: 50px;

    button {
        background: none repeat scroll 0 0 transparent;
        border: medium none;
        border-spacing: 0;
        color: #ffffff;
        font-weight: normal;
        list-style: none outside none;
        margin: 0;
        padding: 0;
        text-align: left;
        text-decoration: none;
        text-indent: 0;
    }

    button {
        @include flexbox(row, center, center);
        border-radius: 30px;
        border: 1px solid $dimed;
        cursor: pointer;

        img {
            width: 40px;
            height: 40px;
            margin: 5px 15px 5px 5px;
        }

        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            margin-right: 20px;
        }
    }

    .loader {
        @include flexbox(row, center, center);
        border-radius: 30px;
        border: 1px solid $dimed;
        cursor: pointer;


        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            margin-right: 20px;
        }

        .lds-ripple {
            display: inline-block;
            position: relative;
            width: 40px;
            height: 40px;
            margin: 5px 15px 5px 0px;
        }

        .lds-ripple div {
            position: absolute;
            border: 2px solid $green;
            opacity: 1;
            border-radius: 50%;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        }

        .lds-ripple div:nth-child(2) {
            animation-delay: -0.5s;
        }

        @keyframes lds-ripple {
            0% {
                top: 18px;
                left: 18px;
                width: 0;
                height: 0;
                opacity: 0;
            }

            4.9% {
                top: 18px;
                left: 18px;
                width: 0;
                height: 0;
                opacity: 0;
            }

            5% {
                top: 18px;
                left: 18px;
                width: 0;
                height: 0;
                opacity: 1;
            }

            100% {
                top: 0px;
                left: 0px;
                width: 36px;
                height: 36px;
                opacity: 0;
            }
        }
    }
}