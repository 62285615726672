@import "../../../styles/index.scss";

.profile-page {
    @include flexbox(column, center, center);

    .profile-block {
        @include flexbox(column, flex-start, center);

        .mobile {
            @include flexbox(column, space-between, center);
            height: calc(100vh - 160px);

            p {
                width: 280px;
            }

            .main-content {
                @include flexbox(column, flex-start, center);
                margin-top: 50px;

                .photo {
                    @include flexbox(column, center, center);
                    height: 200px;

                    img {
                        width: 150px;
                        height: 150px;
                        object-fit: cover;
                        /* border-radius: 50%; */
                    }
                }

                .name_zone {
                    @include flexbox(column, center, center);
                    margin-bottom: 30px;
                    text-align: center;

                    .name {
                        font-size: 32px;
                        line-height: 39px;
                        margin-bottom: 15px;
                    }

                    .email {
                        color: $grey;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }

                .stats_zone {
                    font-size: 16px;
                    line-height: 19px;

                    span {
                        font-weight: 600;
                        color: $green;
                    }

                    .followers {
                        margin-bottom: 15px;
                    }

                    .premium {
                        span {
                            text-transform: capitalize;
                        }
                    }
                }
            }

            .logout {
                @include flexbox(column, center, center);
                width: min-content;
                border-radius: 10px;
                background-color: $red;
                bottom: 50px;

                p {
                    width: max-content;
                    font-size: 16px;
                    line-height: 19px;
                    margin: 5px;
                }
            }

            .logout {
                @include flexbox(column, center, center);
                width: min-content;
                border-radius: 10px;
                background-color: $red;
                bottom: 50px;

                p {
                    width: max-content;
                    font-size: 16px;
                    line-height: 19px;
                    margin: 5px;
                }
            }
        }


        .desktop {
            @include flexbox(row, center, center);
            height: calc(100vh - 300px);

            p {
                width: 450px;
            }

            .photo {
                @include flexbox(column, center, center);
                height: 400px;
                margin-right: 50px;

                img {
                    width: 300px;
                    height: 300px;
                    object-fit: cover;
                    /* border-radius: 50%; */
                }
            }

            .main-content {
                @include flexbox(column, space-between, flex-start);
                height: 300px;

                .name_zone {
                    @include flexbox(column, center, center);
                    margin-bottom: 30px;

                    .name {
                        font-size: 32px;
                        line-height: 39px;
                        margin-bottom: 15px;
                    }

                    .email {
                        color: $grey;
                        font-size: 20px;
                        line-height: 20px;
                    }
                }

                .stats_zone {
                    font-size: 20px;
                    line-height: 20px;

                    span {
                        font-weight: 600;
                        color: $green;
                    }

                    .followers {
                        margin-bottom: 15px;
                    }

                    .premium {
                        span {
                            text-transform: capitalize;
                        }
                    }
                }

                .logout {
                    @include flexbox(row, flex-start, center);
                    width: min-content;
                    border-radius: 10px;
                    background-color: $red;
                    bottom: 50px;

                    p {
                        width: max-content;
                        font-size: 16px;
                        line-height: 19px;
                        margin: 7px;
                    }
                }
            }
        }
    }

    .snackbar {
        position: absolute;
        right: 50%;
        transform: translate(50%);
        bottom: 50px;
        border-radius: 10px;
        background-color: $dimed;
        @include flexbox(column, center, center);


        p {
            margin: 5px;
            width: 100%;
        }
    }

    .report_bug {
        @include flexbox(column, center, center);
        margin: 25px 0px;

        p {
            width: 280px;
            text-align: center;

            @media (min-width: 1000px) {
                width: 500px;
            }

            a {
                color: $green;
                font-weight: 500;
            }
        }
    }
}