@import "../../../styles/index.scss";

.terms-page {
    @include flexbox(column, none, center);


    .terms-block {
        @include flexbox(column, center, center);
        line-height: 120%;

        p {
            font-weight: 300;
            margin: 5px 0px 10px 0px;
        }

        h1 {
            text-align: center;
            margin-bottom: 40px;
        }

        @mixin terms-content($width, $margin-bottom) {
            .terms-content {
                width: $width;
                line-height: 120%;
                margin-bottom: $margin-bottom;

                .heading {
                    font-size: 24px;
                    font-weight: 500;
                    margin-top: 35px;
                    margin-bottom: 15px;
                    line-height: 120%;
                }

                ul {
                    margin-left: 20px;
                    margin-bottom: 10px;

                    li {
                        margin-bottom: 5px;
                    }
                }

                a {
                    color: $green;
                    font-weight: 500;
                }
            }
        }

        .mobile {
            @include terms-content(280px, 25px);
            margin-bottom: 50px;
        }

        .desktop {
            @include terms-content(775px, 50px);
        }
    }
}