@import "../../../styles/index.scss";
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.melodystats_branding {
    @include flexbox(column, flex-start, center);
    margin-top: 50px;

    p {
        font-size: 16px;
        margin-bottom: 15px;
        font-weight: 600;

        span {
            color: $green;
        }
    }

    .logo {
        @include flexbox(row, flex-start, center);

        .logo-image {
            margin-right: 5px;

            img {
                width: 30px;
                height: 30px;
            }
        }

        .naming {
            span {
                font-family: 'Figtree', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                color: $green;
            }
        }
    }
}