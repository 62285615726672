@import "../../../styles/index.scss";

.artists-list-wrapper {
    display: flex;
    flex-wrap: wrap;

    .artists-list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        column-gap: 20px;
        row-gap: 30px;

        @media (min-width: 1000px) {
            column-gap: 30px;
            row-gap: 50px;
        }

        justify-content: center;

        .artists-card {
            @include flexbox(column, space-between, center);
            padding: 2.5px;

            @media (min-width: 1000px) {
                padding: 30px;
            }

            .artist-image {
                width: 75px;
                height: 75px;

                @media (min-width: 1000px) {
                    width: 200px;
                    height: 200px;
                }

                object-fit: cover;
                /* border-radius: 50%; */
            }

            a {
                .spotify_logo_link {
                    width: 25px;
                    height: 25px;
                }
            }


            .artist-name {
                padding-top: 10px;
                font-size: 12px;
                width: 75px;

                @media (min-width: 1000px) {
                    padding-top: 30px;
                    font-size: 20px;
                    width: 200px;
                }


                text-align: center;
                margin-bottom: 12.5px;
            }

            grid-column: span 2;

            &:last-child:nth-child(3n - 1) {
                grid-column-end: -2;
            }

            &:nth-last-child(2):nth-child(3n + 1) {
                grid-column-end: 4;
            }

            &:last-child:nth-child(3n - 2) {
                grid-column-end: 5;
            }

        }
    }
}