@import "../../styles/index.scss";

.blur {
    position: absolute;
    //background: rgba(25, 20, 20, 0.75);
    width: 100%;
    height: 100%;
    transition: 0.5s;
    pointer-events: none; 
    z-index: 350;
}

.blured {
    pointer-events: none;
}

.mobile_menu-wrapper{
    @include flexbox(column, flex-start, center);
}