@import "../../../styles/index.scss";

.ages_of_playlist-page {
    .download-block {
        margin-top: 25px;
    }

    @include flexbox(column, none, center);

    .ages_of_playlist-block {
        @include flexbox(column, center, center);
        margin-bottom: 50px;

        h1 {
            text-align: center;
        }

        .desktop {
            @include flexbox(column, center, center);
            display: flex;
            flex-wrap: wrap;

            .ticket {
                @include flexbox(row, flex-start, center);
                width: 817px;
                height: 204px;
                margin: 25px 0px;

                .ticket-decade {
                    @include flexbox(column, space-between, center);
                    height: 144px;
                    margin: 0px 86px 0px 20px;

                    .decade {
                        font-weight: 700;
                        font-size: 110px;
                        line-height: 105px;
                        text-align: center;
                        width: 400px;
                    }

                    .percentage {
                        font-size: 20px;
                        line-height: 24px;
                        text-align: center;
                        width: 400px;
                    }
                }

                .ticket-track {
                    @include flexbox(column, space-between, flex-start);
                    height: 144px;

                    .track {
                        @include flexbox(row, center, center);

                        .track-image {
                            width: 100px;
                            height: 100px;
                            padding-right: 12.5px;
                        }

                        .track-info {
                            @include flexbox(column, space-between, flex-start);
                            height: 100px;

                            p {
                                width: 181px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }

                            .track-name {
                                font-weight: 700;
                                font-size: 16px;
                                color: $black;
                            }

                            .track-artists {
                                font-weight: 700;
                                font-size: 12px;
                                color: $black;
                            }

                            .track-year {
                                font-weight: 600;
                                font-size: 24px;
                            }

                            .track-spotify_link {
                                width: 25px;
                                height: 25px;
                                margin-top: 12.5px;
                            }
                        }


                    }

                    .description {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        width: 290px;
                    }
                }
            }
        }

        .mobile {
            @include flexbox(column, center, center);
            display: flex;
            flex-wrap: wrap;

            .ticket {
                @include flexbox(column, flex-start, center);
                width: 280px;
                height: 301px;
                margin: 25px 0px;

                .ticket-decade {
                    @include flexbox(column, space-between, center);
                    height: 96px;
                    margin: 15px 0px 40px 0px;
                    text-size-adjust: auto;

                    .decade {
                        font-weight: 700;
                        font-size: 75px;
                        line-height: 72px;
                        text-align: center;
                        width: 250px;
                        color: $black;
                        margin-bottom: 5px;
                    }

                    .percentage {
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        width: 250px;
                    }
                }

                .ticket-track {
                    @include flexbox(column, flex-start, flex-start);
                    height: 250px;

                    .description {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: flex-start;
                        width: 250px;
                    }

                    .track {
                        @include flexbox(row, center, center);
                        margin-top: 10px;

                        .track-image {
                            width: 100px;
                            height: 100px;
                            padding-right: 12.5px;
                        }

                        .track-info {
                            @include flexbox(column, space-between, flex-start);
                            height: 100px;

                            p {
                                width: 139px;
                                @include text-overflow();
                            }

                            .track-name {
                                font-weight: 700;
                                font-size: 16px;
                                color: $black;
                            }

                            .track-year {
                                font-weight: 600;
                                font-size: 24px;
                            }

                            .track-artists {
                                font-weight: 700;
                                font-size: 12px;
                                color: $black;
                            }

                            .track-spotify_link {
                                width: 25px;
                                height: 25px;
                                margin-top: 12.5px;
                            }
                        }
                    }
                }
            }
        }

    }
}