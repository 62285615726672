@import "../../../styles/index.scss";

.loading {
    width: 100vw;
    height: 70vh;
    @include flexbox(column, center, center);

    .loading-content {
        @include flexbox(row, center, center);

        p {
            font-size: 24px;
            font-weight: 600;
        }
    }
}