@import "./defaultReset.scss";

* {
    margin: 0;
    padding: 0;
}

*:focus {
    outline: none;
}


$white: #ffffff;
$grey: #b2b2b2;
$black: #191414;
$green: #1DB954;
$red: #C40233;
$dark-green: #5c8444;
$dimed: #333333;
$yellow: #FFBF00;
$violet: #563FAC;
$blue: #0066FF;

html {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    letter-spacing: normal;
    background: $black;
}

body {
    font-family: 'Figtree', sans-serif;
    color: $white;
    user-select: none;
    min-height: 100vh;
    width: 100vw;
    background: linear-gradient(0deg, #191414 0%, #191414 100%), radial-gradient(205.96% 77.10% at -16.13% 28.18%, rgba(0, 255, 193, 0.10) 0%, rgba(0, 86, 65, 0.00) 100%), radial-gradient(108.47% 109.30% at 105.85% 30.12%, rgba(0, 252, 191, 0.10) 0%, rgba(0, 13, 83, 0.00) 100%), radial-gradient(59.21% 130.03% at 96.08% 98.90%, rgba(36, 0, 255, 0.10) 0%, rgba(18, 0, 126, 0.00) 100%), radial-gradient(58.22% 67.98% at 69.32% 100.07%, rgba(206, 10, 255, 0.10) 0%, rgba(2, 7, 34, 0.00) 100%), radial-gradient(135.29% 99.63% at 0.38% 85.70%, rgba(250, 0, 255, 0.10) 0%, rgba(78, 4, 4, 0.00) 100%), #191414;
    background-blend-mode: difference, normal, normal, normal, normal, normal, normal;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.main {
    transition: 0.5s;
}

@mixin blured-content() {
    pointer-events: none;
    transition: 0.5s;
    filter: blur(5px) brightness(15%);
    -webkit-filter: blur(5px) brightness(15%);
    -moz-filter: blur(5px) brightness(15%);
}

.blured-main {
    height: calc(100vh - 80px);
    @include blured-content();
}

.blured-body {
    @include blured-content();
}

a {
    text-decoration: none;
    color: inherit;
    line-height: 0;
}

@mixin flexbox($direction, $justify, $align) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

@mixin text-overflow() {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.navigation,
.dropdown_menu {
    z-index: 400;
}

.main {
    z-index: 300;
}

.loading-able {
    transition: 0.5s;
}

.loading-able-blur {
    filter: opacity(0);
}

.page_title {
    margin-top: 15px;
    text-align: center;
    width: 280px;
    line-height: 120%;

    @media screen and (min-width: 1000px) {
        width: 600px;
    }
}

.profile_empty-photo {
    @include flexbox(row, center, center);
    border-radius: 100%;
    background-color: $dimed;
    width: 300px;
    height: 300px;
}

picture {
    @include flexbox(column, center, center);
    margin: 0;
    padding: 0;
    border: 0;
}

.hidden_for_share {
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
    z-index: 0;
}

.sharable_content {
    padding: 50px;
    background-color: $black;
    width: max-content;
    @include flexbox(column, center, center);
}

.download-block {
    @include flexbox(column, center, center);
    margin-top: 50px;

    .download-button {
        margin: 10px;
        @include flexbox(column, center, center);

        p {
            text-align: center;
        }
    }
}