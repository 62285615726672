@import "../../../styles/index.scss";

.term_switcher-page {
    @include flexbox(column, center, center);
    margin: 30px;
    text-align: center;

    form {
        @include flexbox(row, space-between, center);
        // width: max-content;

        input[type="radio"] {
            display: none;

            &:checked+label {
                background-color: $dimed;
            }
        }

        label {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 7px;
            border-radius: 10px;
            margin-left: 16px;
            margin-right: 16px;
            font-size: 12px;

            @media screen and (min-width: 1000px) {
                font-size: 16px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}